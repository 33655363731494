@font-face {
  font-family: 'carry-you';
  src: url("/src/carry-you.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  background: url("hearts.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffe0f0;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
}

.App-header {
  color: #d6336c; /* Darker pink for text */
  padding: 20px;
  padding-top: 100px;
  width: 100%;
  text-align: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: start;
  background: linear-gradient(
    to top,
    #ffe0f0 0%, /* Solid white from the bottom */
    #ffe0f0 80%, /* Adjust this value based on the div's height to maintain white for most of its height */
    rgba(255, 255, 255, 0) 100% /* Start fading to transparent towards the top 200px */
  );
}

.text {
  font-size: 40px;
  font-family: 'carry-you', sans-serif;
  text-align: start;
}

.yes-text {
  margin-right: 200px;
  margin-top: 20px;
}

button {
  padding: 10px 20px; 
  border: none;
  border-radius: 5px;
  background-color: #efbcc9; /* Button background */
  color: black; /* Button text color */
  cursor: pointer;
  font-size: 16px;
  min-width: 120px;
}

button:hover {
  background-color: #dc92a3; /* Darken button on hover */
}

.yes-button {
  background-color: #d17385;
  color: black;
  padding: 10px 30px;
}

.yes-button:hover {
  background-color: #bb5763;
}

button:disabled {
  background-color: gray;
}

.bottom-right-img {
  position: fixed; /* Use fixed to keep it at the bottom right regardless of scrolling */
  right: 0;
  bottom: 0;
  z-index: 1000; /* Ensure it's above other content; adjust as necessary */
  height: 200px;
}

.yes-img {
  bottom: -2px;
}